import { Snackbar } from '@mui/material';
import { Alert } from '@mui/lab';

interface Props {
    message: string;
}

const ErrorSnackbar: React.FunctionComponent<Props> = ({ message }) => {
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={true}>
                <Alert severity="error">{message}</Alert>
            </Snackbar>
        </div>
    );
};

export default ErrorSnackbar;
