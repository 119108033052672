import type { FC, ReactNode } from 'react';
import { experimentalStyled } from '@mui/material';
import { useState } from 'react';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { Outlet } from 'react-router-dom';

interface DashboardLayoutProps {
    children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(() => ({
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: '69px',
    [theme.breakpoints.up('lg')]: {
        paddingLeft: '280px',
    },
}));

const DashboardLayoutContainer = experimentalStyled('div')({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
});

const DashbordLayoutContent = experimentalStyled('div')({
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    position: 'relative',
    WebkitOverflowScrolling: 'touch',
});

const DashboardLayout: FC<DashboardLayoutProps> = () => {
    const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);

    return (
        <DashboardLayoutRoot>
            <DashboardNavbar onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)} />
            <DashboardSidebar onMobileClose={(): void => setIsSidebarMobileOpen(false)} openMobile={isSidebarMobileOpen} />
            <DashboardLayoutWrapper>
                <DashboardLayoutContainer>
                    <DashbordLayoutContent>
                        <Outlet />
                    </DashbordLayoutContent>
                </DashboardLayoutContainer>
            </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
    );
};

export default DashboardLayout;
