import ClientOAuth2 from 'client-oauth2';

/**
 * @author Mladen Abaz
 * @description This file contains all the settings used application wide
 */
export class AppSettings {
    public static DEFAULT_INDEX_OF_PAGING: number = 1;
    public static DEFAULT_RANGE: number = 20;
    public static MAX_RANGE: number = 1000;

    /**
     * Custom cookies used in every http request to API
     */
    public static ACCESS_TOKEN_OBJECT_COOKIE: string = 'access_token_obj';
    public static REFRESH_TOKEN_COOKIE: string = 'refresh_token';
    public static USERNAME_COOKIE: string = 'username';
    public static ROLES_COOKIE: string = 'roles';

    /**
     * Custom keys for storing data in browsers localStorage
     */
    public static SETTINGS_LS_KEY = 'settings';
    public static ACCESS_TOKEN_LS_KEY = 'accessToken';
    public static REFRESH_TOKEN_LS_KEY = 'refreshToken';

    /**
     * Frequently used HTTP headers
     */
    public static ACCEPT_LANGUAGE_HEADER: string = 'Accept-Language';
    public static CSRF_HTTP_COOKIE_NAME: string = 'XSRF-TOKEN';
    public static CSRF_CUSTOM_HTTP_HEADER_NAME: string = 'x-csrf-token';

    /**
     * Observable subscribe timeout.
     * Every subscribe (ajax call or store select) should timeout after this period
     */
    public static HTTP_AJAX_TIMEOUT = 120000;

    /**
     * Get oAuth client config object, used in components for
     * fetching auth code and access token afterwards
     */
    public static get OAUTH_CLIENT(): ClientOAuth2 {
        return new ClientOAuth2({
            accessTokenUri: process.env.REACT_APP_ACCESS_URL,
            authorizationUri: process.env.REACT_APP_AUTH_URL,
            clientId: process.env.REACT_APP_CLIENT_ID,
            clientSecret: process.env.REACT_APP_CLIENT_SECRET,
            redirectUri: process.env.REACT_APP_REDIRECT_URL + '/login',
            scopes: ['read'],
        });
    }
}
