import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

interface IAuthState {
    isUserAuthenticated: boolean;
}

const initialState: IAuthState = {
    isUserAuthenticated: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsAuthenticated(state: IAuthState, action: PayloadAction<boolean>): void {
            state.isUserAuthenticated = action.payload;
        }
    }
});

export const setIsUserAuthenticated = (isAuthenticated: boolean): AppThunk => async (dispatch): Promise<void> => {
    dispatch(authSlice.actions.setIsAuthenticated(isAuthenticated));
};

export const { reducer } = authSlice;

export default authSlice;
