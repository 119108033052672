import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import * as DataControllerApi from '../api/dataControllerApi';
import {
    Account,
    AmlQuestionnaireDataResponse,
    BlacklistDescription,
    Category,
    ConsentDescription,
    IdentyumPersonalData,
    IdentyumServiceDataResponse,
    Psd2ServiceAccountsResponse,
    Psd2ServiceAccountTransactionsResponse,
    Psd2ServiceDataResponse,
    TosServiceDataResponse,
    Transaction,
} from 'kent-process-monitor-api';
import { ErrorType } from '../lib/constants/errorTypes';

interface IInitialState {
    amlData: {
        questionnaireName: string | undefined;
        categories: Array<Category> | undefined;
    };
    blacklistData: {
        blacklists: Array<BlacklistDescription> | undefined;
    };
    idyData: {
        personalData: IdentyumPersonalData | undefined;
    };
    psd2Data: {
        accounts: Array<Account> | undefined;
        transactions: Array<Transaction> | undefined;
    };
    tosData: {
        acceptedConsents: Array<ConsentDescription> | undefined;
    };
    chosenSessionId: string | undefined;
    isLoading: boolean;
    error: ErrorType | undefined;
}

const initialState: IInitialState = {
    amlData: {
        questionnaireName: undefined,
        categories: undefined,
    },
    blacklistData: {
        blacklists: undefined,
    },
    idyData: {
        personalData: undefined,
    },
    psd2Data: {
        accounts: undefined,
        transactions: undefined,
    },
    tosData: {
        acceptedConsents: undefined,
    },
    chosenSessionId: undefined,
    isLoading: false,
    error: undefined,
};

const dataControllerSlice = createSlice({
    name: 'dataController',
    initialState,
    reducers: {
        getAmlData(state: IInitialState, action: PayloadAction<AmlQuestionnaireDataResponse>) {
            state.amlData.questionnaireName = action.payload.questionnaireName;
            state.amlData.categories = action.payload.categories;
            state.isLoading = false;
        },
        getBlacklistData(state: IInitialState, action: PayloadAction<Psd2ServiceDataResponse>) {
            state.blacklistData.blacklists = action.payload.blacklists;
            state.isLoading = false;
        },
        getIdyData(state: IInitialState, action: PayloadAction<IdentyumServiceDataResponse>) {
            state.idyData.personalData = action.payload.personalData;
            state.isLoading = false;
        },
        getPsd2Data(state: IInitialState, action: PayloadAction<Psd2ServiceAccountsResponse>) {
            state.psd2Data.accounts = action.payload.accounts;
            state.isLoading = false;
        },
        getPsd2Transactions(state: IInitialState, action: PayloadAction<Psd2ServiceAccountTransactionsResponse>) {
            state.psd2Data.transactions = action.payload.transactions;
            state.isLoading = false;
        },
        getTosData(state: IInitialState, action: PayloadAction<TosServiceDataResponse>) {
            state.tosData.acceptedConsents = action.payload.acceptedConsents;
            state.isLoading = false;
        },
        setChosenSessionId(state: IInitialState, action: PayloadAction<string>) {
            state.chosenSessionId = action.payload;
        },
        setLoading(state: IInitialState, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setError(state: IInitialState, action: PayloadAction<ErrorType>) {
            state.isLoading = false;
            state.error = action.payload;
        },
        clearError(state: IInitialState) {
            state.error = undefined;
        },
    },
});

export const getAmlData =
    (sessionId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(dataControllerSlice.actions.setChosenSessionId(sessionId));
        dispatch(dataControllerSlice.actions.setLoading(true));
        try {
            const amlDataResponse = await DataControllerApi.getAmlData(sessionId);
            dispatch(dataControllerSlice.actions.getAmlData(amlDataResponse));
        } catch (error: any) {
            dispatch(dataControllerSlice.actions.setError(ErrorType[error?.response?.data?.type] ?? ErrorType.GENERIC));
        }
    };

export const getBlacklistData =
    (sessionId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(dataControllerSlice.actions.setChosenSessionId(sessionId));
        dispatch(dataControllerSlice.actions.setLoading(true));
        try {
            const blacklistDataResponse = await DataControllerApi.getBlacklistData(sessionId);
            dispatch(dataControllerSlice.actions.getBlacklistData(blacklistDataResponse));
        } catch (error: any) {
            dispatch(dataControllerSlice.actions.setError(ErrorType[error?.response?.data?.type] ?? ErrorType.GENERIC));
        }
    };

export const getIdyData =
    (sessionId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(dataControllerSlice.actions.setChosenSessionId(sessionId));
        dispatch(dataControllerSlice.actions.setLoading(true));
        try {
            const idyDataResponse = await DataControllerApi.getIdentyumData(sessionId);
            dispatch(dataControllerSlice.actions.getIdyData(idyDataResponse));
        } catch (error: any) {
            dispatch(dataControllerSlice.actions.setError(ErrorType[error?.response?.data?.type] ?? ErrorType.GENERIC));
        }
    };

export const getPsd2Data =
    (sessionId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(dataControllerSlice.actions.setChosenSessionId(sessionId));
        dispatch(dataControllerSlice.actions.setLoading(true));
        try {
            const psd2DataResponse = await DataControllerApi.getPsd2AccountsData(sessionId);
            dispatch(dataControllerSlice.actions.getPsd2Data(psd2DataResponse));
        } catch (error: any) {
            dispatch(dataControllerSlice.actions.setError(ErrorType[error?.response?.data?.type] ?? ErrorType.GENERIC));
        }
    };

export const getPsd2Transactions =
    (sessionId: string, accountId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(dataControllerSlice.actions.setChosenSessionId(sessionId));
        dispatch(dataControllerSlice.actions.setLoading(true));
        try {
            const psd2TransactionsResponse = await DataControllerApi.getPsd2TransactionsData(sessionId, accountId);
            dispatch(dataControllerSlice.actions.getPsd2Transactions(psd2TransactionsResponse));
        } catch (error: any) {
            dispatch(dataControllerSlice.actions.setError(ErrorType[error?.response?.data?.type] ?? ErrorType.GENERIC));
        }
    };

export const getTosData =
    (sessionId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(dataControllerSlice.actions.setChosenSessionId(sessionId));
        dispatch(dataControllerSlice.actions.setLoading(true));
        try {
            const tosDataResponse = await DataControllerApi.getTosData(sessionId);
            dispatch(dataControllerSlice.actions.getTosData(tosDataResponse));
        } catch (error: any) {
            dispatch(dataControllerSlice.actions.setError(ErrorType[error?.response?.data?.type] ?? ErrorType.GENERIC));
        }
    };

export const { clearError } = dataControllerSlice.actions;

export const { reducer } = dataControllerSlice;

export default dataControllerSlice;
