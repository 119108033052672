import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { IToken } from '../../model/common/token/token';
import Cookies from 'universal-cookie/es6';
import { AppSettings } from '../../common/app-settings';
import * as authApiService from '../../api/authApi';
import jwt_decode from 'jwt-decode';
import { ICustomJwtPayload } from '../../context/useAuthProvider';

interface State {
    isInitialized: boolean;
    isAuthenticated: boolean;
    userName: string | null;
    userRoles: string[];
    token: IToken | null;
}

export interface AuthContextValue extends State {
    handleLoginSuccess: (accessToken: IToken, userName: string, userRoles: string[]) => Promise<void>;
    refreshToken: () => Promise<void>;
    logout: () => Promise<void>;
}

interface AuthProviderProps {
    children: ReactNode;
}

type InitializeAction = {
    type: 'INITIALIZE';
    payload: {
        isAuthenticated: boolean;
        userName: string | null;
        userRoles: string[] | [];
        token: IToken | null;
    };
};

type LoginSuccessfulAction = {
    type: 'LOGIN_SUCCESSFUL';
    payload: {
        userName: string | null;
        userRoles: string[];
        token: IToken | null;
    };
};

type RefreshTokenAction = {
    type: 'LOGIN_SUCCESSFUL';
    payload: {
        userName: string | null;
        userRoles: string[];
        token: IToken | null;
    };
};

type LogoutAction = {
    type: 'LOGOUT';
};

type Action =
    | InitializeAction
    | LoginSuccessfulAction
    | RefreshTokenAction
    | LogoutAction;

const initialState: State = {
    isAuthenticated: false,
    isInitialized: false,
    token: null,
    userName: null,
    userRoles: [],
};

const handlers: Record<string, (state: State, action: Action) => State> = {
    INITIALIZE: (state: State, action: Action): State => {
        const { isAuthenticated, userName, userRoles, token } = (action as InitializeAction).payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            token,
            userName,
            userRoles,
        };
    },
    LOGIN_SUCCESSFUL: (state: State, action: Action): State => {
        const { userName, userRoles, token } = (action as LoginSuccessfulAction).payload;

        return {
            ...state,
            isAuthenticated: true,
            token,
            userName,
            userRoles
        };
    },
    LOGOUT: (state: State): State => ({
        ...state,
        isAuthenticated: false,
        token: null,
        userName: null,
        userRoles: []
    })
};

const reducer = (state: State, action: Action): State => (
    handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
    ...initialState,
    handleLoginSuccess: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    refreshToken: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);
    const cookies = new Cookies();
    const logoutLink: string = process.env.REACT_APP_LOGOUT_URL || '';
    const redirectUrl: string = process.env.REACT_APP_REDIRECT_URL || '';

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            try {

                const accessToken: IToken = cookies.get(AppSettings.ACCESS_TOKEN_OBJECT_COOKIE);
                const refreshToken: string = cookies.get(AppSettings.REFRESH_TOKEN_COOKIE);
                const userName: string = cookies.get(AppSettings.USERNAME_COOKIE);
                const userRoles: string[] = cookies.get(AppSettings.ROLES_COOKIE);

                if (accessToken) {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: true,
                            token: accessToken,
                            userName,
                            userRoles
                        }
                    });
                } else if(!accessToken && refreshToken && refreshToken !== 'undefined') {
                    const token: IToken = await authApiService.refreshToken(refreshToken);
                    const jwtDecodedToken: ICustomJwtPayload = jwt_decode(token.accessToken);
                    if (token.accessToken) {
                        cookies.set(AppSettings.ACCESS_TOKEN_OBJECT_COOKIE, token, {expires: token.expires});
                    }
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: !!token.accessToken,
                            token: token,
                            userName: jwtDecodedToken.preferred_username,
                            userRoles: jwtDecodedToken.realm_access.roles
                        }
                    });
                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: false,
                            token: null,
                            userName: null,
                            userRoles: []
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        token: null,
                        userName: null,
                        userRoles: []
                    }
                });
            }
        };

        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoginSuccess = async (accessToken: IToken, userName: string, userRoles: string[]): Promise<void> => {
        if (accessToken) {
            cookies.set(AppSettings.ACCESS_TOKEN_OBJECT_COOKIE, accessToken, {expires: accessToken.expires});
        }
        if (accessToken.refreshToken) {
            cookies.set(AppSettings.REFRESH_TOKEN_COOKIE, accessToken.refreshToken, {expires: accessToken.refreshExpires});
        }
        cookies.set(AppSettings.USERNAME_COOKIE, userName, {expires: accessToken.expires});
        cookies.set(AppSettings.ROLES_COOKIE, userRoles, {expires: accessToken.expires});

        dispatch({
            type: 'LOGIN_SUCCESSFUL',
            payload: {
                token: accessToken,
                userName,
                userRoles
            }
        });
    };

    const logout = async (): Promise<void> => {
        cookies.remove(AppSettings.ACCESS_TOKEN_OBJECT_COOKIE);
        cookies.remove(AppSettings.REFRESH_TOKEN_COOKIE);
        cookies.remove(AppSettings.USERNAME_COOKIE);
        cookies.remove(AppSettings.ROLES_COOKIE);

        dispatch({ type: 'LOGOUT' });
        window.location.href = `${logoutLink}?redirect_uri=${redirectUrl}`;
    };

    const refreshToken = async (): Promise<void> => {
        // odradi refresh tokena
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                handleLoginSuccess,
                logout,
                refreshToken
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default AuthContext;
