import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Process, SearchResponse } from 'kent-process-monitor-api';
import { AppThunk } from '../store';
import * as ProcessMonitorApi from '../api/processMonitorApi';
import { ErrorType } from '../lib/constants/errorTypes';

interface IProcessState {
    processes: Array<Process> | undefined;
    total: number | undefined;
    isLoading: boolean;
    error: ErrorType | undefined;
}

const initialState: IProcessState = { processes: undefined, total: undefined, isLoading: false, error: undefined };

const processMonitorSlice = createSlice({
    name: 'processMonitor',
    initialState,
    reducers: {
        getProcesses(state: IProcessState, action: PayloadAction<SearchResponse>) {
            state.processes = action.payload.processes;
            state.total = action.payload.total;
            state.isLoading = false;
        },
        setLoading(state: IProcessState, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setError(state: IProcessState, action: PayloadAction<ErrorType>) {
            state.isLoading = false;
            state.error = action.payload;
        },
        clearError(state: IProcessState) {
            state.error = undefined;
        },
    },
});

export const getProcesses =
    (fromRow: number, rowsPerPage: number, startDate: string, finishDate: string, showOnlyErrors: boolean, searchTerm: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(processMonitorSlice.actions.setLoading(true));
        try {
            const searchResponse = await ProcessMonitorApi.getProcesses(fromRow, rowsPerPage, startDate, finishDate, showOnlyErrors, searchTerm);
            dispatch(processMonitorSlice.actions.getProcesses(searchResponse));
        } catch (error: any) {
            dispatch(processMonitorSlice.actions.setError(ErrorType[error?.response?.data?.type] ?? ErrorType.GENERIC));
        }
    };

export const { clearError } = processMonitorSlice.actions;

export const { reducer } = processMonitorSlice;

export default processMonitorSlice;
