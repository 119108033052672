import { useRoutes } from 'react-router';
import routes from './routes';
import { createTheme, ThemeProvider } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import './i18n';

function App() {
    const content = useRoutes(routes);

    const theme = createTheme({
        typography: {
            fontFamily: 'Montserrat, sans-serif !important',
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: '600',
                    },
                },
            },
        },
        palette: {
            primary: { main: '#987c64' },
            secondary: { main: '#000' },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Toaster position="top-center" />
            {content}
        </ThemeProvider>
    );
}

export default App;
