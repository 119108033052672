import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import * as TosControllerApi from '../api/tosControllerApi';
import { CreateGroupRequest, DocumentContent } from 'kent-tos-service-api';
import { ErrorType } from '../lib/constants/errorTypes';
import { AlertColor } from '@mui/material';

interface IInitialState {
    pdfBase64Content: string | undefined;
    consentDialogOpen: boolean;
    isLoading: boolean;
    error: ErrorType | undefined;
    alert: {
        open: boolean;
        message: string;
        severity: AlertColor;
    };
}

const initialState: IInitialState = {
    pdfBase64Content: undefined,
    consentDialogOpen: false,
    isLoading: false,
    error: undefined,
    alert: {
        open: false,
        message: '',
        severity: 'success',
    },
};

const consentContentSlice = createSlice({
    name: 'consentContent',
    initialState,
    reducers: {
        getConsentContent(state: IInitialState, action: PayloadAction<DocumentContent>) {
            state.pdfBase64Content = action.payload.pdfBase64Content;
            state.consentDialogOpen = true;
        },
        closeConsentDialog(state: IInitialState) {
            state.consentDialogOpen = false;
        },
        createGroup(state: IInitialState, action: PayloadAction<CreateGroupRequest>) {
            state.alert = { open: true, message: 'Dokument uspješno poslan!', severity: 'success' };
        },
        setGroupError(state: IInitialState) {
            state.alert = { open: true, message: 'Slanje neuspješno!', severity: 'error' };
        },
        setAlert(state: IInitialState, action: PayloadAction<any>) {
            state.alert = { open: action.payload.open, message: action.payload.message, severity: action.payload.severity };
        },
        setLoading(state: IInitialState, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setError(state: IInitialState, action: PayloadAction<ErrorType>) {
            state.isLoading = false;
            state.error = action.payload;
        },
        clearError(state: IInitialState) {
            state.error = undefined;
        },
    },
});

export const getConsentContent =
    (consentId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            const consentResponse = await TosControllerApi.getConsentContent(consentId);
            dispatch(consentContentSlice.actions.getConsentContent(consentResponse));
        } catch (error: any) {
            dispatch(consentContentSlice.actions.setError(ErrorType[error?.response?.data?.type] ?? ErrorType.GENERIC));
        }
    };

export const createGroup =
    (createGroupRequest: CreateGroupRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(consentContentSlice.actions.setLoading(true));
        try {
            dispatch(consentContentSlice.actions.createGroup(createGroupRequest));
        } catch (error: any) {
            dispatch(consentContentSlice.actions.setGroupError());
        }
    };

export const { clearError } = consentContentSlice.actions;

export const { setAlert } = consentContentSlice.actions;

export const { closeConsentDialog } = consentContentSlice.actions;

export const { reducer } = consentContentSlice;

export default consentContentSlice;
