import { TablePagination } from '@mui/material';
import { useGridApiContext, useGridRootProps } from '@mui/x-data-grid';
import { ChangeEvent, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

const CustomPagination = () => {
    const { t } = useTranslation();
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();

    const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        apiRef.current.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        apiRef.current.setPageSize(parseInt(event.target.value, 10));
        apiRef.current.setPage(0);
    };

    const handleDisplayRows = ({ from, to, count }) => {
        return `${from}-${to} od ${count !== -1 ? count : '-'}`;
    };

    return (
        <TablePagination
            component="div"
            count={apiRef.current.state.pagination.rowCount}
            page={apiRef.current.state.pagination.page}
            labelDisplayedRows={handleDisplayRows}
            labelRowsPerPage={t('grid_rowsperpage')}
            onPageChange={handleChangePage}
            rowsPerPage={apiRef.current.state.pagination.pageSize}
            rowsPerPageOptions={rootProps.rowsPerPageOptions}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
};

export default CustomPagination;
