import { Transaction } from 'kent-process-monitor-api';
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
    transactions: Array<Transaction> | undefined;
    expandedStatus: boolean;
}

const AccountTransactions: React.FC<Props> = ({ transactions, expandedStatus }) => {
    const { t } = useTranslation();

    return (
        <>
            {transactions?.map((transaction) => (
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    key={transaction.transactionId}
                    style={{
                        display: expandedStatus ? 'flex' : 'none',
                        borderTop: '1px solid #e0e0e0',
                        borderBottom: '1px solid #e0e0e0',
                        padding: '2rem 0',
                    }}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={9}>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {transaction?.transactionId && (
                                            <TableRow>
                                                <TableCell align="center">{t('psd2_transactionid')}</TableCell>
                                                <TableCell align="center">{transaction?.transactionId}</TableCell>
                                            </TableRow>
                                        )}
                                        {transaction?.entryReference && (
                                            <TableRow>
                                                <TableCell align="center">{t('psd2_entryreference')}</TableCell>
                                                <TableCell align="center">{transaction?.entryReference}</TableCell>
                                            </TableRow>
                                        )}
                                        {transaction?.endToEndId && (
                                            <TableRow>
                                                <TableCell align="center">End-to-end ID</TableCell>
                                                <TableCell align="center">{transaction?.endToEndId}</TableCell>
                                            </TableRow>
                                        )}
                                        {transaction?.bookingDate && (
                                            <TableRow>
                                                <TableCell align="center">{t('psd2_bookingdate')}</TableCell>
                                                <TableCell align="center">{transaction?.bookingDate}</TableCell>
                                            </TableRow>
                                        )}
                                        {transaction?.valueDate && (
                                            <TableRow>
                                                <TableCell align="center">{t('psd2_valuedate')}</TableCell>
                                                <TableCell align="center">{transaction?.valueDate}</TableCell>
                                            </TableRow>
                                        )}
                                        {transaction?.transactionAmount && (
                                            <TableRow>
                                                <TableCell align="center">{t('psd2_transactionamount')}</TableCell>
                                                <TableCell align="center">
                                                    {transaction?.transactionAmount?.amount}
                                                    {transaction?.transactionAmount?.currency}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {transaction?.creditorName && (
                                            <TableRow>
                                                <TableCell align="center">{t('psd2_creditorname')}</TableCell>
                                                <TableCell align="center">{transaction?.creditorName}</TableCell>
                                            </TableRow>
                                        )}
                                        {transaction?.creditorAccount && (
                                            <>
                                                <TableRow>
                                                    <TableCell align="center">{t('psd2_creditoriban')}</TableCell>
                                                    <TableCell align="center">{transaction?.creditorAccount.iban}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center">{t('psd2_currency')}</TableCell>
                                                    <TableCell align="center">{transaction?.creditorAccount.currency}</TableCell>
                                                </TableRow>
                                            </>
                                        )}
                                        {transaction?.ultimateCreditor && (
                                            <TableRow>
                                                <TableCell align="center">{t('psd2_ultimatecreditor')}</TableCell>
                                                <TableCell align="center">{transaction?.ultimateCreditor}</TableCell>
                                            </TableRow>
                                        )}
                                        {transaction?.debtorName && (
                                            <TableRow>
                                                <TableCell align="center">{t('psd2_debtorname')}</TableCell>
                                                <TableCell align="center">{transaction?.debtorName}</TableCell>
                                            </TableRow>
                                        )}
                                        {transaction?.debtorAccount && (
                                            <>
                                                <TableRow>
                                                    <TableCell align="center">{t('psd2_debtoriban')}</TableCell>
                                                    <TableCell align="center">{transaction?.debtorAccount.iban}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center">{t('psd2_currency')}</TableCell>
                                                    <TableCell align="center">{transaction?.debtorAccount.currency}</TableCell>
                                                </TableRow>
                                            </>
                                        )}
                                        {transaction?.ultimateDebtor && (
                                            <TableRow>
                                                <TableCell align="center">{t('psd2_ultimatedebtor')}</TableCell>
                                                <TableCell align="center">{transaction?.ultimateDebtor}</TableCell>
                                            </TableRow>
                                        )}
                                        {transaction?.remittanceInformationUnstructured && (
                                            <TableRow>
                                                <TableCell align="center">{t('psd2_transactioninfo')}</TableCell>
                                                <TableCell align="center">{transaction?.remittanceInformationUnstructured}</TableCell>
                                            </TableRow>
                                        )}
                                        {transaction?.remittanceInformationStructured && (
                                            <TableRow>
                                                <TableCell align="center">{t('psd2_additionalinfo')}</TableCell>
                                                <TableCell align="center">{transaction?.remittanceInformationStructured}</TableCell>
                                            </TableRow>
                                        )}
                                        {transaction?.purposeCode && (
                                            <TableRow>
                                                <TableCell align="center">{t('psd2_purposecode')}</TableCell>
                                                <TableCell align="center">{transaction?.purposeCode}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-around" alignItems="center">
                        {transaction?.currencyExchange?.map((exchange) => (
                            <Grid item xs={12} md={5} key={exchange?.contractIdentification}>
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center">{t('psd2_sourcecurrency')}</TableCell>
                                                <TableCell align="center">{exchange?.sourceCurrency ?? '-'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center">{t('psd2_targetcurrency')}</TableCell>
                                                <TableCell align="center">{exchange?.targetCurrency ?? '-'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center">{t('psd2_exchangerate')}</TableCell>
                                                <TableCell align="center">{exchange?.exchangeRate ?? '-'}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            ))}
        </>
    );
};

export default AccountTransactions;
