import { FC, ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { AuthorizePage } from '../Login/AuthorizePage';

interface AuthGuardProps {
    children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
    const { children } = props;
    const auth = useAuth();
    const location = useLocation();
    const [ requestedLocation ] = useState<string>('');

    const setRedirectLink = () => {
        localStorage.removeItem('redirectLink');
        if (location.pathname && location.pathname !== '/' && !location.pathname.includes('login') && !location.pathname.includes('redirect')) {
            localStorage.setItem('' +
                'redirectLink', location.pathname);
        }
    };

    if (!auth.isAuthenticated) {
        setRedirectLink();
        return <AuthorizePage />;
    }

    if (requestedLocation && location.pathname !== requestedLocation) {
        return <Navigate to={ requestedLocation } />;
    }

    return <>{ children }</>;
};

export default AuthGuard;
