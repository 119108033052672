import { IconButton, TableCell, TableRow } from '@mui/material';
import { CancelRounded, Check, Clear, Help, Info, RemoveCircle } from '@mui/icons-material';
import 'date-fns';
import { format } from 'date-fns';
import { Component, Process } from 'kent-process-monitor-api';
import { useState } from 'react';
import TableRowDetails from './detailsShow';
import { ComponentType } from '../../lib/constants/componentTypes';
import InfoDetails from './infoShow';
import styles from './style.module.scss';

const cellWidth = {
    info: '80px',
    sessionId: '260px',
    date: '150px',
    icons: '120px',
};

interface Props {
    proc: Process;
}

enum ExpandOption {
    NONE = '',
    INFO = 'info',
    COMPONENTS = 'components',
}

const todayStart = new Date();
todayStart.setHours(0, 0, 0, 0);
const todayFinish = new Date();
todayFinish.setHours(23, 59, 59, 59);

const TableRowProcess: React.FC<Props> = ({ proc }) => {
    const [isExpanded, setIsExpanded] = useState({ expanded: false, show: ExpandOption.NONE, component: '' });

    const getStageStatus = (stage: Component | undefined, componentType: ComponentType) => {
        return (
            <TableCell align="center" width={cellWidth.icons} className={styles.cellHeight}>
                {resolveStageStatus(stage, componentType)}
            </TableCell>
        );
    };

    const expandingFunction = (componentType: string, expandOption: ExpandOption) => {
        if (expandOption === ExpandOption.INFO) {
            if (isExpanded.expanded) {
                return setIsExpanded({ expanded: false, show: ExpandOption.NONE, component: '' });
            }
            return setIsExpanded({ expanded: true, show: ExpandOption.INFO, component: '' });
        }
        if (isExpanded.component !== componentType) {
            return setIsExpanded({ expanded: true, show: ExpandOption.COMPONENTS, component: componentType });
        }
        return setIsExpanded({ expanded: false, show: ExpandOption.NONE, component: '' });
    };

    const resolveStageStatus = (stage: Component | undefined, componentType: ComponentType) => {
        if (!stage) {
            return <Clear />;
        }
        if (!stage.start) {
            return <Help />;
        }
        if (stage.passed) {
            if (stage.passed === true) {
                return (
                    <IconButton
                        className={`${styles.noPadding} ${styles.pointer}`}
                        color="secondary"
                        component="span"
                        onClick={() => expandingFunction(componentType, ExpandOption.COMPONENTS)}>
                        <Check style={{ color: 'green' }} />
                    </IconButton>
                );
            }
            return (
                <IconButton
                    className={`${styles.noPadding} ${styles.pointer}`}
                    color="secondary"
                    component="span"
                    onClick={() => expandingFunction(componentType, ExpandOption.COMPONENTS)}>
                    <CancelRounded color="error" />
                </IconButton>
            );
        }
        if (stage.finish) {
            return (
                <IconButton
                    className={`${styles.noPadding} ${styles.pointer}`}
                    color="secondary"
                    component="span"
                    onClick={() => expandingFunction(componentType, ExpandOption.COMPONENTS)}>
                    <Check style={{ color: 'green' }} />
                </IconButton>
            );
        }
        if (stage.errors && stage.errors.length > 0) {
            return (
                <IconButton
                    className={`${styles.noPadding} ${styles.pointer}`}
                    color="secondary"
                    component="span"
                    onClick={() => expandingFunction(componentType, ExpandOption.COMPONENTS)}>
                    <CancelRounded color="error" />
                </IconButton>
            );
        }
        return <RemoveCircle color="error" />;
    };

    return (
        <>
            <TableRow style={{ backgroundColor: proc.errors && proc.errors?.length > 0 ? 'pink' : '' }}>
                <TableCell width={cellWidth.info} className={styles.cellHeight}>
                    {(proc.networkData || proc.personalData) && (
                        <IconButton className={styles.noPadding} color="secondary" component="span" onClick={() => expandingFunction('', ExpandOption.INFO)}>
                            <Info />
                        </IconButton>
                    )}
                </TableCell>
                <TableCell align="right" width={cellWidth.sessionId} className={styles.cellHeight}>
                    {proc.sessionId}
                </TableCell>
                <TableCell align="right" width={cellWidth.date} className={styles.cellHeight}>
                    {format(new Date(proc.initTime), 'dd.MM.yyyy. HH:mm')}
                </TableCell>
                {getStageStatus(proc.tosService, ComponentType.TOS_SERVICE)}
                {getStageStatus(proc.identyumService, ComponentType.IDENTYUM_SERVICE)}
                {getStageStatus(proc.blacklistChecker, ComponentType.BLACKLIST_CHECKER)}
                {getStageStatus(proc.psd2Service, ComponentType.PSD2_SERVICE)}
                {getStageStatus(proc.amlChecker, ComponentType.AML_QUESTIONNAIRE)}
                {getStageStatus(proc.loanConfigurator, ComponentType.LOAN_CONFIGURATOR)}
            </TableRow>
            <TableRowDetails
                isExpanded={isExpanded.expanded && isExpanded.show === ExpandOption.COMPONENTS ? true : false}
                component={isExpanded.component}
                sessionId={proc.sessionId}
            />
            <InfoDetails isExpanded={isExpanded.expanded && isExpanded.show === ExpandOption.INFO ? true : false} process={proc} />
        </>
    );
};

export default TableRowProcess;
