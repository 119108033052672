import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Check, Clear } from '@mui/icons-material';
import { Account } from 'kent-process-monitor-api';
import { useTranslation } from 'react-i18next';

interface Props {
    account: Account | undefined;
    expandedStatus: boolean;
}

const AccountInfo: React.FC<Props> = ({ account, expandedStatus }) => {
    const { t } = useTranslation();

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
                display: expandedStatus ? 'flex' : 'none',
                borderTop: '1px solid #e0e0e0',
                borderBottom: '1px solid #e0e0e0',
                padding: '2rem 0',
            }}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={9}>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableBody>
                                {account?.currency && (
                                    <TableRow>
                                        <TableCell align="center">{t('psd2_currency')}</TableCell>
                                        <TableCell align="center">{account?.currency}</TableCell>
                                    </TableRow>
                                )}
                                {account?.name && (
                                    <TableRow>
                                        <TableCell align="center">{t('name')}</TableCell>
                                        <TableCell align="center">{account?.name}</TableCell>
                                    </TableRow>
                                )}
                                {account?.displayName && (
                                    <TableRow>
                                        <TableCell align="center">{t('psd2_displayname')}</TableCell>
                                        <TableCell align="center">{account?.displayName}</TableCell>
                                    </TableRow>
                                )}
                                {account?.cashAccountType && (
                                    <TableRow>
                                        <TableCell align="center">{t('psd2_cashacctype')}</TableCell>
                                        <TableCell align="center">{account?.cashAccountType}</TableCell>
                                    </TableRow>
                                )}
                                {account?.status && (
                                    <TableRow>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">{account?.status}</TableCell>
                                    </TableRow>
                                )}
                                {account?.usage && (
                                    <TableRow>
                                        <TableCell align="center">{t('psd2_usage')}</TableCell>
                                        <TableCell align="center">{account?.usage === 'PRIV' ? 'Privatno' : 'Poslovno'}</TableCell>
                                    </TableRow>
                                )}
                                {account?.ownerName && (
                                    <TableRow>
                                        <TableCell align="center">{t('psd2_ownername')}</TableCell>
                                        <TableCell align="center">{account?.ownerName}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-around" alignItems="center">
                {account?.balances?.map((balance) => (
                    <Grid item xs={12} md={5} key={balance?.lastChangeDateTime}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center">{t('psd2_balancetype')}</TableCell>
                                        <TableCell align="center">{balance?.balanceType ?? '-'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">{t('psd2_amount')}</TableCell>
                                        <TableCell align="center">
                                            {balance?.balanceAmount?.amount ?? '-'}
                                            {balance?.balanceAmount?.currency ?? '-'}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">{t('psd2_creditlimit')}</TableCell>
                                        <TableCell align="center">
                                            {balance?.creditLimitIncluded ? <Check style={{ color: 'green' }} /> : <Clear color="error" />}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export default AccountInfo;
