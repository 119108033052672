import { ICustomJwtPayload } from '../context/useAuthProvider';
import { IToken } from '../model/common/token/token';
import { ApiToken } from '../api/authApi';
import { Roles } from '../lib/constants/roles';

export class TokenHelper {
    public static getUsernameFromToken(token: ICustomJwtPayload): string {
        return token.preferred_username;
    }

    public static resolveRedirectPathForUserType(token: ICustomJwtPayload): string {
        if (token.realm_access.roles.includes(Roles.requestView)) {
            return '/overview';
        }
        if (token.realm_access.roles.includes(Roles.termsRead)) {
            return '/consents';
        }
        return '/no-privileges';
    }

    public static getUserRolesFromToken(token: ICustomJwtPayload): string[] {
        return token.realm_access.roles;
    }

    public static convertApiTokenToIToken(apiToken: ApiToken): IToken {
        return {
            accessToken: apiToken.access_token,
            expires: new Date(Date.now() + apiToken.expires_in * 1000),
            expiresIn: apiToken.expires_in,
            refreshExpires: new Date(Date.now() + apiToken.refresh_expires_in * 1000),
            refreshExpiresIn: apiToken.refresh_expires_in,
            refreshToken: apiToken.refresh_token,
        } as IToken;
    }
}
