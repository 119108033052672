import type { FC, ReactNode } from 'react';
import { List, ListProps, ListSubheader } from '@mui/material';
import { matchPath } from 'react-router-dom';
import NavItem from './NavItem';

interface INavSectionItem {
    path?: string;
    icon?: ReactNode;
    info?: ReactNode;
    children?: INavSectionItem[];
    title: string;
}

interface INavSectionRenderItem {
    acc?: JSX.Element[];
    items: INavSectionItem[];
    pathname: string;
    depth?: number;
}

interface INavSectionProps extends ListProps {
    items: INavSectionItem[];
    pathname: string;
    title: string;
}

interface INavSectionRenderChildItem {
    acc: JSX.Element[];
    pathname: string;
    item: INavSectionItem;
    depth: number;
}

const reduceChildRoutes = ({ acc, pathname, item, depth }: INavSectionRenderChildItem): JSX.Element[] => {
    const key = `${item.title}-${depth}`;
    const exactMatch = item.path ? !!matchPath('', pathname) : false;

    if (item.children) {
        const partialMatch = item.path ? !!matchPath('', pathname) : false;
        acc.push(
            <NavItem active={partialMatch} depth={depth} icon={item.icon} info={item.info} key={key} open={partialMatch} path={item.path} title={item.title}>
                {renderNavItems({ depth: depth + 1, items: item.children, pathname })}
            </NavItem>
        );
    } else {
        acc.push(<NavItem active={exactMatch} depth={depth} icon={item.icon} info={item.info} key={key} path={item.path} title={item.title} />);
    }

    return acc;
};

const renderNavItems = ({ depth = 0, items, pathname }: INavSectionRenderItem): JSX.Element => (
    <List disablePadding>
        {items.reduce((acc: JSX.Element[], item) =>
            reduceChildRoutes({ acc, depth, item, pathname }), [])}
    </List>
);

const NavSection: FC<INavSectionProps> = (props) => {
    const { items, pathname, title, ...other } = props;
    return (
        <List
            subheader={
                <ListSubheader
                    disableGutters
                    disableSticky
                    sx={{
                        color: 'text.primary',
                        fontSize: '0.75rem',
                        fontWeight: 700,
                        lineHeight: 2.5,
                        textTransform: 'uppercase',
                    }}>
                    {title}
                </ListSubheader>
            }
            {...other}>
            {renderNavItems({ items, pathname })}
        </List>
    );
};

export default NavSection;
