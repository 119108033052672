import type { FC } from 'react';
import type { DropzoneOptions } from 'react-dropzone';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import {
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import DuplicateIcon from '../../assets/icons/Duplicate';
import XIcon from '../../assets/icons/X';
import { toast } from 'react-hot-toast';
import addFile from '../../assets/img/illustrations/add_file.svg';
import { useTranslation } from 'react-i18next';
import { UploadFile } from '@mui/icons-material';

interface FileDropzoneProps extends DropzoneOptions {
    files: File[];
    isFilesPanelVisible: boolean;
    onRemove?: (file: File) => void;
    setFiles: (files: File[]) => void;
    title?: string;
    description?: string;
}

/* eslint-disable no-restricted-properties */
const bytesToSize = (bytes: number, decimals = 2): string => {
    if (bytes === 0) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${ parseFloat(( bytes / Math.pow(k, i) ).toFixed(dm)) } ${ sizes[i] }`;
};

const FileDropzone: FC<FileDropzoneProps> = (props: FileDropzoneProps) => {
    const {
        accept,
        description,
        files,
        isFilesPanelVisible,
        maxFiles,
        maxSize,
        minSize,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onDrop,
        onRemove,
        setFiles,
        title,
    } = props;
    const { t } = useTranslation();
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    const onDropAccepted = (acceptedFiles: File[]) => {
        setFiles(acceptedFiles);
    };

    const onDropRejected = (fileRejections: FileRejection[]) => {
        const tooLargeFiles = fileRejections.pop()?.errors.filter(error => error.code === ErrorCode.FileTooLarge);
        if (tooLargeFiles && tooLargeFiles.length > 0) {
            toast.error(t('dragndrop_tooLargeFile'));
        } else {
            toast.error(t('dragndrop_notSupportedAttachment'));
        }
    };

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        accept,
        disabled: files.length > 0,
        maxFiles,
        maxSize,
        minSize,
        multiple: false,
        onDropAccepted,
        onDropRejected
    });

    const dropzoneInfoText = () => {
        return isDragActive ? (
            currentDropStatus()
        ) : (
            <Typography color='textPrimary' variant='body1'>
                { description ? description : t('dragndrop_text') }
            </Typography>
        );
    };

    const currentDropStatus = () => {
        return isDragReject ? (
            <Typography color='textPrimary' variant='body1'>
                { t('dragndrop_notSupportedAttachment') }
            </Typography>
        ) : (
            <Typography color='textPrimary' variant='body1'>
                { t('dragndrop_text') }
            </Typography>
        );
    };

    const handleRemoveFile = (file: File) => {
        onRemove && onRemove(file);
    };

    return (
        <div>
            { smUp ? <Box
                    sx={ {
                        ...( files.length === 0 && {
                            '&:hover': {
                                backgroundColor: 'action.hover',
                                cursor: 'pointer',
                                opacity: 0.5
                            }
                        } ),
                        alignItems: 'center',
                        backgroundColor: 'background.default',
                        border: 1,
                        borderColor: 'divider',
                        borderRadius: 1,
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        outline: 'none',
                        ...( isDragActive && {
                            backgroundColor: 'action.active',
                            opacity: 0.5
                        } ),
                        p: 6
                    } }
                    { ...getRootProps() }>
                    <input { ...getInputProps() } />
                    <Box
                        sx={ {
                            '& img': {
                                width: 100
                            }
                        } }>
                        <img alt='Select file' src={ addFile } />
                    </Box>
                    <Box sx={ { p: 2 } }>
                        <Typography color='textPrimary' variant='h6'>
                            { title ? title : t('dragndrop_selectFile') }
                        </Typography>
                        <Box sx={ { mt: 2 } }>{ dropzoneInfoText() }</Box>
                    </Box>
                </Box> :
                <Box sx={ { textAlign: 'center' } }>
                    <Button variant='outlined' startIcon={ <UploadFile fontSize='small' /> } component='label'>
                        Import file
                        <input{ ...getInputProps() } type='file' hidden />
                    </Button>
                </Box>
            }
            { isFilesPanelVisible && files.length > 0 && (
                <Box sx={ { mt: 2 } }>
                    <List>
                        { files?.map((file) => (
                            <ListItem
                                key={ file.name }
                                sx={ {
                                    '& + &': {
                                        mt: 1
                                    },
                                    border: 1,
                                    borderColor: 'divider',
                                    borderRadius: 1
                                } }>
                                <ListItemIcon>
                                    <DuplicateIcon fontSize='small' />
                                </ListItemIcon>
                                <ListItemText
                                    primary={ file.name }
                                    primaryTypographyProps={ {
                                        color: 'textPrimary',
                                        variant: 'subtitle2'
                                    } }
                                    secondary={ bytesToSize(file.size) }
                                />
                                <Tooltip title='Remove'>
                                    <IconButton edge='end' onClick={ () => handleRemoveFile(file) }>
                                        <XIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                        )) }
                    </List>
                </Box>
            ) }
            { isFilesPanelVisible && files.length === 0 && (
                <Box sx={ { mt: 2 } }>
                    <List>
                        <ListItem
                            sx={ {
                                '& + &': {
                                    mt: 1
                                },
                                border: 1,
                                borderColor: 'divider',
                                borderRadius: 1,
                                textAlign: 'center'
                            } }>
                            <ListItemText
                                primary={ t('dragndrop_noFilesAddedPrimary') }
                                secondary={ t('dragndrop_noFilesAddedSecondary') }
                                primaryTypographyProps={ {
                                    color: 'textPrimary',
                                    variant: 'subtitle2'
                                } }
                            />
                        </ListItem>
                    </List>
                </Box>
            ) }
        </div>
    );
};

export default FileDropzone;
