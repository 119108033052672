import { useEffect, useState } from 'react';
import { Account } from 'kent-process-monitor-api';
import { Grid, IconButton } from '@mui/material';
import { Info, Receipt } from '@mui/icons-material';
import AccountInfo from './psd2AccountDetails/accInfo';
import AccountTransactions from './psd2AccountDetails/accTransactions';
import { getPsd2Data, getPsd2Transactions } from '../../../slices/dataController';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface Props {
    sessionId: string;
    isExpanded: boolean;
}

enum ExpandOption {
    NONE = '',
    INFO = 'info',
    TRANSACTIONS = 'transactions',
}

const PSD2Service: React.FC<Props> = ({ sessionId, isExpanded }) => {
    const dispatch = useAppDispatch();
    const { psd2Data } = useAppSelector((state) => state.dataController);
    const [expandStatus, setExpandStatus] = useState({ expanded: false, show: ExpandOption.NONE, account: '', index: 0 });

    useEffect(() => {
        if (isExpanded) {
            dispatch(getPsd2Data(sessionId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpanded]);

    const expandingFunction = (showType: ExpandOption, accountId: string, index: number) => {
        if (expandStatus.show !== showType) {
            return setExpandStatus({ expanded: true, show: showType, account: accountId, index: index });
        }
        return setExpandStatus({ expanded: false, show: ExpandOption.NONE, account: '', index: 0 });
    };

    const transactionClickHandler = (account: Account, i: number) => {
        dispatch(getPsd2Transactions(sessionId, account?.resourceId));
        expandingFunction(ExpandOption.TRANSACTIONS, account?.resourceId, i);
    };

    return (
        <>
            {psd2Data?.accounts?.map((account, i) => (
                <>
                    <Grid container justifyContent="space-between" alignItems="center" key={account?.resourceId}>
                        <div>
                            <IconButton color="secondary" component="span" onClick={() => expandingFunction(ExpandOption.INFO, account?.resourceId, i)}>
                                <Info />
                            </IconButton>
                            {account?.iban}
                        </div>
                        <div>
                            {account?.product}
                            <IconButton color="secondary" component="span" onClick={() => transactionClickHandler(account, i)}>
                                <Receipt />
                            </IconButton>
                        </div>
                    </Grid>
                    {expandStatus.expanded && expandStatus.show === ExpandOption.INFO && (
                        <AccountInfo
                            account={psd2Data?.accounts?.find((account) => account.resourceId === expandStatus.account)}
                            expandedStatus={expandStatus.show && expandStatus.index === i ? true : false}
                        />
                    )}
                    {expandStatus.expanded && expandStatus.show === ExpandOption.TRANSACTIONS && (
                        <AccountTransactions
                            transactions={psd2Data?.transactions}
                            expandedStatus={expandStatus.show && expandStatus.index === i ? true : false}
                        />
                    )}
                </>
            ))}
        </>
    );
};

export default PSD2Service;
