import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AssignmentInd, Description, RequestPage } from '@mui/icons-material';
import { Avatar, Box, Divider, Drawer, Theme, Typography, useMediaQuery } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Scrollbar from '../scrollbar/Scrollbar';
import NavSection from '../navsection/NavSection';
import { getAvatarName } from '../../helpers/helpers';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';

interface DashboardSidebarProps {
    onMobileClose: () => void;
    openMobile: boolean;
}

interface IMenuSectionItem {
    title: string;
    path: string;
    icon: ReactNode;
}

interface IMenuSection {
    title: string;
    items: IMenuSectionItem[];
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
    const { t } = useTranslation();
    const { userName } = useAuth();
    const { onMobileClose, openMobile } = props;
    const location = useLocation();
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    const sections: IMenuSection[] = [
        {
            title: `${t('links_title')}`,
            items: [
                {
                    title: `${t('links_requests')}`,
                    path: '/overview',
                    icon: <RequestPage fontSize="small" />,
                },
                {
                    title: `${t('links_documents')}`,
                    path: '/consents',
                    icon: <Description fontSize="small" />,
                },
                {
                    title: `${t('links_processes')}`,
                    path: '/processes',
                    icon: <AssignmentInd fontSize="small" />,
                },
            ],
        },
    ];

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Scrollbar options={{ suppressScrollX: true }}>
                <Box sx={{ display: { lg: 'none', xs: 'flex' }, justifyContent: 'center', p: 2 }}>
                    <RouterLink to="/"></RouterLink>
                </Box>
                <Box sx={{ p: 2 }}>
                    <Box sx={{ alignItems: 'center', backgroundColor: 'background.default', borderRadius: 1, display: 'flex', overflow: 'hidden', p: 2 }}>
                        {userName && <Avatar sx={{ cursor: 'pointer', height: 48, width: 48 }}>{getAvatarName(userName)}</Avatar>}
                        <Box sx={{ ml: 2 }}>
                            <Typography color="textPrimary" variant="subtitle2">
                                {userName}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box sx={{ p: 2 }}>
                    {sections.map((section: IMenuSection) => (
                        <NavSection key={section.title} pathname={location.pathname} {...section} sx={{ '& + &': { mt: 3 } }} />
                    ))}
                </Box>
                <Box sx={{ p: 2 }}>
                    <Divider />
                    {/*<Typography color="textPrimary" variant="subtitle2">*/}
                    {/*    Need help?*/}
                    {/*</Typography>*/}
                </Box>
            </Scrollbar>
        </Box>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'Background.paper',
                        height: 'calc(100% - 69px) !important',
                        top: '69px !important',
                        width: 280,
                    },
                }}
                variant="permanent">
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onMobileClose}
            open={openMobile}
            PaperProps={{
                sx: {
                    backgroundColor: 'background.paper',
                    width: 280,
                },
            }}
            variant="temporary">
            {content}
        </Drawer>
    );
};

export default DashboardSidebar;
