export abstract class ErrorMessagesProvider  {
    
    static errorDictionary = new Map<number, string>([
        [400, "Error occured please contact administrator!"],
        [401, "Authorization failed! Try to login again later!"],
        [403, "Forbidden! You dont have rights to access this!"],
        [404, "Not found!"],
        [405, "Method is not allowed!"],
        [500, "Error occured please contact administrator!"]
    ]);
  
    static provideMessages() : Map<number, string> {
       return this.errorDictionary
    }
}