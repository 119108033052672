import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { IOrder, ITableRowData } from '../../interfaces/KentAdministratorInterfaces';
import { ErrorModelProvider } from '../../errorHandling/ErrorModelProvider';
import { formatDate } from '../../helpers/helpers';
import { croLocale } from '../../helpers/dataGridLocales';
import CustomPagination from '../TableOverrides/CustomPagination';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { FormControl, Grid, IconButton, InputAdornment, MenuItem, TextField } from '@mui/material';
import { Info, Search } from '@mui/icons-material';
import { Skeleton } from '@mui/lab';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs, { Dayjs } from 'dayjs';

const CustomToolbar = (): JSX.Element => {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
};

const RequestOverview: React.FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { token } = useAuth();
    const [tableData, setTableData] = useState<Array<ITableRowData>>();
    const [pageSize, setPageSize] = useState<number>(10);
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('day'));
    const [finishDate, setFinishDate] = useState<Dayjs>(dayjs().endOf('day'));
    const [amountFrom, setAmountFrom] = useState<number>();
    const [amountTo, setAmountTo] = useState<number>();
    const [status, setStatus] = useState('ALL');
    const [merchant, setMerchant] = useState('ALL');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const handleMount = async () => {
            getListData();
        };

        handleMount();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleAmountFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const amount = parseInt(event.target.value);
        setAmountFrom(Number.isNaN(amount) ? undefined : amount);
    };

    const handleAmountToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const amount = parseInt(event.target.value);
        setAmountTo(Number.isNaN(amount) ? undefined : amount);
    };

    const handleStatusSelect = (event: React.BaseSyntheticEvent) => {
        setStatus(event.target.value);
    };

    const handleMerchantSelect = (event: React.BaseSyntheticEvent) => {
        setMerchant(event.target.value);
    };

    const editSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleStartDateChange = (date: Dayjs | null) => {
        setStartDate(dayjs(date).startOf('day'));
    };

    const handleFinishDateChange = (date: Dayjs | null) => {
        setFinishDate(dayjs(date).startOf('day'));
    };

    const getListData = async () => {
        axios
            .get(`${process.env.REACT_APP_KENT_ADMIN_URL}/api/v1/orders`, {
                headers: { Authorization: `Bearer ${token?.accessToken}` },
            })
            .then((response) => {
                setTableData(
                    response.data.orders.map((order: IOrder) => {
                        return {
                            id: order.id,
                            createdAt: order.createdAt,
                            firstName: order.customerData.firstName,
                            lastName: order.customerData.lastName,
                            oib: order.customerData.personalNumber,
                            status: order.status,
                            merchantId: order.merchantData.id,
                            merchantName: order.merchantData.name,
                            amount: order.loanDetails?.amount,
                            order: order,
                        };
                    })
                );
            })
            .catch((err) => {
                let error = ErrorModelProvider.provide(err as AxiosError);
                console.error('error', err, error);
            });
    };

    const handlePageSizeChange = (num: number) => {
        setPageSize(num);
    };

    const widths = {
        info: 66,
        created: 160,
        firstName: 130,
        lastName: 130,
        status: 105,
        oib: 120,
        amount: 130,
        merchantName: 120,
    };
    const headers = {
        created: `${t('requests_grid_created')}`,
        name: `${t('requests_grid_name')}`,
        surname: `${t('requests_grid_family')}`,
        status: 'Status',
        oib: 'OIB',
        amount: `${t('requests_grid_amount')}`,
        merchantName: `${t('requests_grid_merchant')}`,
    };
    const columns: GridColDef[] = [
        {
            field: '',
            headerName: '',
            width: widths.info,
            renderCell: (params) => {
                const onClick = () => {
                    navigate('/overview/orderinfo', { state: { order: params.row.id } });
                };
                if (!tableData)
                    return (
                        <Grid container justifyContent="center">
                            <Skeleton variant="circular" width={20} height={20} animation="wave" />
                        </Grid>
                    );
                return (
                    <strong>
                        <IconButton color="secondary" component="span" onClick={onClick}>
                            <Info />
                        </IconButton>
                    </strong>
                );
            },
        },
        {
            field: 'id',
            headerName: headers.created,
            width: widths.created,
            valueGetter: (params) => {
                if (tableData) return new Date(params.row.createdAt);
            },
            valueFormatter: (params) => {
                if (tableData) return formatDate(new Date(params.value));
            },
            renderCell: () => {
                if (!tableData) return <Skeleton variant="text" width={'100%'} height={20} animation="wave" />;
            },
        },
        {
            field: 'firstName',
            headerName: headers.name,
            width: widths.firstName,
            renderCell: () => {
                if (!tableData) return <Skeleton variant="text" width={'100%'} height={20} animation="wave" />;
            },
        },
        {
            field: 'lastName',
            headerName: headers.surname,
            width: widths.lastName,
            renderCell: () => {
                if (!tableData) return <Skeleton variant="text" width={'100%'} height={20} animation="wave" />;
            },
        },
        {
            field: 'oib',
            headerName: headers.oib,
            width: widths.oib,
            renderCell: () => {
                if (!tableData) return <Skeleton variant="text" width={'100%'} height={20} animation="wave" />;
            },
        },
        {
            field: 'amount',
            headerName: headers.amount,
            width: widths.amount,
            valueFormatter: (params) => {
                if (tableData) return 'HRK ' + (params.value?.toLocaleString(undefined, { minimumFractionDigits: 2 }) ?? '-');
            },
            renderCell: () => {
                if (!tableData) return <Skeleton variant="text" width={'100%'} height={20} animation="wave" />;
            },
        },
        {
            field: 'status',
            headerName: headers.status,
            width: widths.status,
            renderCell: () => {
                if (!tableData) return <Skeleton variant="text" width={'80%'} height={20} animation="wave" />;
            },
        },
        {
            field: 'merchantName',
            headerName: headers.merchantName,
            width: widths.merchantName,
            renderCell: () => {
                if (!tableData) return <Skeleton variant="text" width={'100%'} height={20} animation="wave" />;
            },
        },
    ];

    const getRows = (): Array<ITableRowData> | Array<Object> => {
        if (tableData === undefined) {
            return [...Array(10)].map((_, i) => {
                return { id: i.toString(), amount: '', firstName: '', lastName: '', oib: '', status: true, merchantName: '' };
            });
        }

        return tableData
            .filter((request: ITableRowData) => {
                return startDate === undefined || dayjs(request.createdAt) >= startDate;
            })
            .filter((request: ITableRowData) => {
                return finishDate === undefined || dayjs(request.createdAt) <= finishDate;
            })
            .filter((request: ITableRowData) => {
                return amountFrom === undefined || request.amount >= amountFrom;
            })
            .filter((request: ITableRowData) => {
                return amountTo === undefined || request.amount <= amountTo;
            })
            .filter((request: ITableRowData) => {
                return status === 'ALL' || request.status === status;
            })
            .filter((request: ITableRowData) => {
                return merchant === 'ALL' || request.merchantId === merchant;
            })
            .filter((request: ITableRowData) => {
                return (
                    request.firstName.toLowerCase().includes(searchTerm) ||
                    request.lastName.toLowerCase().includes(searchTerm) ||
                    request.oib.toLowerCase().includes(searchTerm)
                );
            });
    };

    return (
        <div className={styles.root}>
            <Grid container direction="column" justifyContent="center" className={styles.mainContainer}>
                <Grid container direction="row" alignItems="center" justifyContent="space-around">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label={t('requests_from')}
                            inputFormat="DD.MM.YYYY"
                            value={startDate}
                            onChange={handleStartDateChange}
                            maxDate={finishDate}
                            className={styles.filter}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <DesktopDatePicker
                            label={t('requests_to')}
                            inputFormat="DD.MM.YYYY"
                            value={finishDate}
                            onChange={handleFinishDateChange}
                            minDate={startDate}
                            className={styles.filter}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid container direction="row" alignItems="center" justifyContent="space-around">
                    <TextField
                        name="start"
                        type="number"
                        label={t('requests_from')}
                        value={amountFrom}
                        onChange={handleAmountFromChange}
                        className={styles.filter}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">HRK</InputAdornment>,
                        }}
                    />
                    <TextField
                        name="finish"
                        type="number"
                        label={t('requests_to')}
                        value={amountTo}
                        onChange={handleAmountToChange}
                        className={styles.filter}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">HRK</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid container direction="row" alignItems="center" justifyContent="space-around" style={{ marginTop: '2rem' }}>
                    <FormControl variant="outlined" className={styles.filter}>
                        <TextField select label="STATUS" defaultValue={status} onChange={handleStatusSelect}>
                            <MenuItem value="ALL">{t('requests_status_all')}</MenuItem>
                            <MenuItem value="INITIATED">{t('requests_status_initiated')}</MenuItem>
                            <MenuItem value="COMPLETED">{t('requests_status_completed')}</MenuItem>
                            <MenuItem value="REJECTED">{t('requests_status_rejected')}</MenuItem>
                        </TextField>
                    </FormControl>
                    <FormControl variant="outlined" className={styles.filter}>
                        <TextField select label={t('requests_merchant')} defaultValue={merchant} onChange={handleMerchantSelect}>
                            <MenuItem value="ALL">{t('requests_status_all')}</MenuItem>
                            {tableData &&
                                [
                                    ...new Set(
                                        tableData
                                            .map(function (value) {
                                                return { merchantId: value.merchantId, merchantName: value.merchantName };
                                            })
                                            .map((uniqueStr) => JSON.stringify(uniqueStr))
                                    ),
                                ]
                                    .map((uniqueObj) => JSON.parse(uniqueObj))
                                    .map((merch, i) => (
                                        <MenuItem key={i} value={merch.merchantId}>
                                            {merch.merchantName}
                                        </MenuItem>
                                    ))}
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid container direction="row" alignItems="center" justifyContent="center" style={{ marginTop: '2rem' }}>
                    <TextField
                        name="search"
                        onChange={editSearchTerm}
                        type="text"
                        label={t('requests_search')}
                        value={searchTerm}
                        className={styles.filter}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search color="secondary" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid container direction="row" alignItems="center" justifyContent="space-around" style={{ marginTop: '2rem' }}></Grid>
                <div style={{ width: '100%' }}>
                    <DataGrid
                        autoHeight
                        disableSelectionOnClick
                        disableColumnMenu
                        disableDensitySelector
                        disableColumnSelector
                        pageSize={pageSize}
                        onPageSizeChange={handlePageSizeChange}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        pagination
                        rows={getRows()}
                        columns={columns}
                        localeText={i18n.language === 'hr' ? croLocale : undefined}
                        components={{
                            Toolbar: CustomToolbar,
                            Pagination: CustomPagination,
                        }}
                    />
                </div>
            </Grid>
        </div>
    );
};

export default RequestOverview;
