import { LinearProgress, TableCell, TableRow } from '@mui/material';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ErrorSnackbar from '../errorSnackbar';
import TOSService from './componentsDetails/tosService';
import { ComponentType } from '../../lib/constants/componentTypes';
import AMLQuestionnaire from './componentsDetails/amlQuestionnaire';
import BlacklistChecker from './componentsDetails/blacklistChecker';
import IdentyumService from './componentsDetails/identyumService';
import LoanConfigurator from './componentsDetails/loanConfigurator';
import PSD2Service from './componentsDetails/psd2Service';
import styles from './style.module.scss';
import { useAppSelector } from '../../store/hooks';

interface Props {
    isExpanded: boolean;
    component: string;
    sessionId: string;
}

const TableRowDetails: React.FC<Props> = ({ isExpanded, component, sessionId }) => {
    const { isLoading, chosenSessionId, error } = useAppSelector((state) => state.dataController);

    const handleComponentRender = () => {
        if (component === ComponentType.AML_QUESTIONNAIRE) {
            return <AMLQuestionnaire sessionId={sessionId} isExpanded={isExpanded} />;
        }
        if (component === ComponentType.BLACKLIST_CHECKER) {
            return <BlacklistChecker sessionId={sessionId} isExpanded={isExpanded} />;
        }
        if (component === ComponentType.IDENTYUM_SERVICE) {
            return <IdentyumService sessionId={sessionId} isExpanded={isExpanded} />;
        }
        if (component === ComponentType.LOAN_CONFIGURATOR) {
            return <LoanConfigurator sessionId={sessionId} isExpanded={isExpanded} />;
        }
        if (component === ComponentType.PSD2_SERVICE) {
            return <PSD2Service sessionId={sessionId} isExpanded={isExpanded} />;
        }
        return <TOSService sessionId={sessionId} isExpanded={isExpanded} />;
    };

    return (
        <>
            {isLoading && chosenSessionId === sessionId && (
                <TableCell colSpan={9} padding="none">
                    <LinearProgress color="primary" />
                </TableCell>
            )}
            {error && <ErrorSnackbar message={error} />}
            <TableRow className={styles.root2} style={{ display: isExpanded ? 'table-row' : 'none' }}>
                <TableCell colSpan={9}>{handleComponentRender()}</TableCell>
            </TableRow>
        </>
    );
};

export default TableRowDetails;
