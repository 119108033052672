import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { AppSettings } from '../common/app-settings';
import { IToken } from '../model/common/token/token';
import { TokenHelper } from '../utils/token-helper';

export interface ApiToken {
    access_token: string;
    expires_in: number;
    refresh_expires_in: number;
    refresh_token: string;
    scope: string;
    token_type: string;
}

const emptyToken: IToken = {
    accessToken: '',
    expires: new Date(),
    expiresIn: 0,
    refreshExpires: new Date(),
    refreshExpiresIn: 0,
    refreshToken: ''
};

const axiosClient: AxiosInstance = axios.create();

const accessTokenLink: string = process.env.REACT_APP_ACCESS_TOKEN_URL || '';
const clientId: string = process.env.REACT_APP_CLIENT_ID || '';

export const refreshToken = (refreshToken: string): Promise<IToken> => {
    const bodyData = new URLSearchParams({
        client_id: clientId,
        grant_type: AppSettings.REFRESH_TOKEN_COOKIE,
        refresh_token: refreshToken
    });

    return axiosClient.post(`${accessTokenLink}`, bodyData)
        .then((response: AxiosResponse<ApiToken>) => {
            if (response.status >= 200 && response.status < 300) {
                return TokenHelper.convertApiTokenToIToken(response.data);
            }
            return emptyToken;
        })
        .catch(error => {
            return emptyToken;
        });
};
