export const croLocale = {
    // Root
    noRowsLabel: 'Nema zapisa',
    noResultsOverlayLabel: 'Rezultat nije pronađen.',
    errorOverlayDefaultLabel: 'Dogodila se greška.',

    // Density selector toolbar button text
    toolbarDensity: 'Gustoća',
    toolbarDensityLabel: 'Gustoća',
    toolbarDensityCompact: 'Kompaktno',
    toolbarDensityStandard: 'Standarno',
    toolbarDensityComfortable: 'Komforno',

    // Columns selector toolbar button text
    toolbarColumns: 'Stupci',
    toolbarColumnsLabel: 'Odaberi stupce',

    // Filters toolbar button text
    toolbarFilters: 'Filteri',
    toolbarFiltersLabel: 'Prikaži filtere',
    toolbarFiltersTooltipHide: 'Sakrij filtere',
    toolbarFiltersTooltipShow: 'Prikaži filtere',
    toolbarFiltersTooltipActive: (count) => (count !== 1 ? `${count} aktivni filteri` : `${count} aktivni filter`),

    // Export selector toolbar button text
    toolbarExport: 'Izvezi',
    toolbarExportLabel: 'Izvezi',
    toolbarExportCSV: 'Spremi kao CSV',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Pronađi stupac',
    columnsPanelTextFieldPlaceholder: 'Naslov stupca',
    columnsPanelDragIconLabel: 'Rasporedi stupce',
    columnsPanelShowAllButton: 'Prikaži sve',
    columnsPanelHideAllButton: 'Sakrij sve',

    // Filter panel text
    filterPanelAddFilter: 'Dodaj filter',
    filterPanelDeleteIconLabel: 'Obriši',
    filterPanelOperators: 'Operatori',
    filterPanelOperatorAnd: 'I',
    filterPanelOperatorOr: 'Ili',
    filterPanelColumns: 'Stupci',
    filterPanelInputLabel: 'Vrijednost',
    filterPanelInputPlaceholder: 'Vrjednost filtera',

    // Filter operators text
    filterOperatorContains: 'sadrži',
    filterOperatorEquals: 'jednako',
    filterOperatorStartsWith: 'počinje sa',
    filterOperatorEndsWith: 'završava sa',
    filterOperatorIs: 'je',
    filterOperatorNot: 'nije',
    filterOperatorAfter: 'ne nakon',
    filterOperatorOnOrAfter: 'je na ili poslije',
    filterOperatorBefore: 'je prije',
    filterOperatorOnOrBefore: 'je na ili prije',

    // Filter values text
    filterValueAny: 'any',
    filterValueTrue: 'true',
    filterValueFalse: 'false',

    // Column menu text
    columnMenuLabel: 'Meni',
    columnMenuShowColumns: 'Prikaži stupce',
    columnMenuFilter: 'Filteri',
    columnMenuHideColumn: 'Sakrij',
    columnMenuUnsort: 'Poništi',
    columnMenuSortAsc: 'Sortiraj ASC',
    columnMenuSortDesc: 'Sortiraj DESC',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) => (count !== 1 ? `${count} aktivni filteri` : `${count} aktivni filter`),
    columnHeaderFiltersLabel: 'Prikaži filtere',
    columnHeaderSortIconLabel: 'Sortiraj',

    // Rows selected footer text
    footerRowSelected: (count) => (count !== 1 ? `${count.toLocaleString()} odabrani redovi` : `${count.toLocaleString()} odabrani red`),

    // Total rows footer text
    footerTotalRows: 'Ukupno redova:',

    // Total visible rows footer text
    footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount.toLocaleString()} od ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Checkbox selekcija',

    // Boolean cell text
    booleanCellTrueLabel: 'true',
    booleanCellFalseLabel: 'false',

    // Used core components translation keys
    // MuiTablePagination: {},

    filterOperatorIsEmpty: '',
    filterOperatorIsNotEmpty: '',
    MuiTablePagination: {},
};
