import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Process } from 'kent-process-monitor-api';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';

interface Props {
    isExpanded: boolean;
    process: Process;
}

const InfoDetails: React.FC<Props> = ({ isExpanded, process }) => {
    const { t } = useTranslation();

    return (
        <TableRow className={styles.root} style={{ display: isExpanded ? 'table-row' : 'none' }}>
            <TableCell colSpan={9}>
                <Grid container justifyContent="center" alignItems="center">
                    {process?.personalData && (
                        <Grid item xs={12} md={9} className={styles.titles}>
                            <h3>{t('processes_personaldata')}</h3>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {process?.personalData?.firstName && (
                                            <TableRow>
                                                <TableCell align="center">{t('requests_grid_name')}</TableCell>
                                                <TableCell align="center">{process?.personalData?.firstName}</TableCell>
                                            </TableRow>
                                        )}
                                        {process?.personalData?.lastName && (
                                            <TableRow>
                                                <TableCell align="center">{t('requests_grid_family')}</TableCell>
                                                <TableCell align="center">{process?.personalData?.lastName}</TableCell>
                                            </TableRow>
                                        )}
                                        {process?.personalData?.personalNumber && (
                                            <TableRow>
                                                <TableCell align="center">OIB</TableCell>
                                                <TableCell align="center">{process?.personalData?.personalNumber}</TableCell>
                                            </TableRow>
                                        )}
                                        {process?.personalData?.documentNumber && (
                                            <TableRow>
                                                <TableCell align="center">{t('processes_docnumber')}</TableCell>
                                                <TableCell align="center">{process?.personalData?.documentNumber}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                    {process?.networkData && (
                        <Grid item xs={12} md={9} className={styles.titles}>
                            <h3>{t('processes_networkdata')}</h3>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {process?.networkData?.ip && (
                                            <TableRow>
                                                <TableCell align="center">{t('processes_ipaddress')}</TableCell>
                                                <TableCell align="center">{process?.networkData?.ip}</TableCell>
                                            </TableRow>
                                        )}
                                        {process?.networkData?.userAgent && (
                                            <TableRow>
                                                <TableCell align="center">{t('processes_useragent')}</TableCell>
                                                <TableCell align="center">{process?.networkData?.userAgent}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default InfoDetails;
