import { Grid, Typography } from '@mui/material';
import styles from './style.module.scss';

interface INoPrivilegesProps {}

const NoPrivileges: React.FunctionComponent<INoPrivilegesProps> = (props) => {
    return (
        <Grid container direction="column" alignItems="center" justifyContent="center" className={styles.mainDiv}>
            <Typography variant="h5">Nemate potrebne privilegije za nastavak</Typography>
            <br />
            <Typography variant="body1">Obratite se svom sistem administratoru</Typography>
        </Grid>
    );
};

export default NoPrivileges;
