import type { FC } from 'react';
import { AppBarProps, Avatar, Button } from '@mui/material';
import { AppBar, Box, experimentalStyled, Toolbar } from '@mui/material';
// import LanguagePopover from './LanguagePopover';
import { useAuth } from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { getAvatarName } from '../../helpers/helpers';

interface DashboardNavbarProps extends AppBarProps {
    onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(() => ({
    zIndex: 1100,
}));

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
    const { onSidebarMobileOpen, ...other } = props;
    const { t } = useTranslation();
    const { userName } = useAuth();
    const auth = useAuth();

    const handleLogout = async () => {
        await auth.logout();
    };

    return (
        <DashboardNavbarRoot {...other}>
            <Toolbar sx={{ minHeight: 64 }}>
                <Box sx={{ ml: 1 }}>{userName && <Avatar sx={{ height: 32, width: 32 }}>{getAvatarName(userName)}</Avatar>}</Box>
                <Box sx={{ flexGrow: 1, ml: 2 }} />
                {/* <LanguagePopover /> */}
                <Box sx={{ p: 2 }}>
                    <Button color="secondary" variant="contained" fullWidth onClick={handleLogout}>
                        {t('account_logout')}
                    </Button>
                </Box>
            </Toolbar>
        </DashboardNavbarRoot>
    );
};

export default DashboardNavbar;
