import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { getBase64Image } from '../../helpers/helpers';
import { Card, Button, CardContent, CardHeader, CardMedia, Grid, TableContainer, Table, TableCell, TableBody, TableRow, Box } from '@mui/material';
import { IOrder } from '../../interfaces/KentAdministratorInterfaces';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import styles from './style.module.scss';
import { ArrowBackIos } from '@mui/icons-material';

const OrderInfo = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { token } = useAuth();

    const [orderInfo, setOrderInfo] = useState<IOrder>();

    const customerData = orderInfo?.customerData;
    const contact = orderInfo?.contact;

    useEffect(() => {
        Object.keys(location).forEach((el) => {
            if (el === 'state') {
                let _state: any = location[el];
                getOrderInfo(_state.order);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getOrderInfo = (id) => {
        axios
            .get(`${process.env.REACT_APP_KENT_ADMIN_URL}/api/v1/orders/${id}`, {
                headers: { Authorization: `Bearer ${token?.accessToken}` },
            })
            .then((response) => {
                setOrderInfo(response.data);
            })
            .catch((err) => {
                console.error('error', err);
            });
    };

    const handleBackClick = () => {
        navigate('/overview');
    };

    return (
        <div className={styles.mainContainer}>
            <Box>
                <Button className={styles.backBtn} variant="contained" onClick={handleBackClick}>
                    <ArrowBackIos /> {t('back_button')}
                </Button>
                {orderInfo && (
                    <>
                        <CardContent>
                            <Grid container direction="row">
                                <Grid container item xs={12} direction="row" spacing={4}>
                                    {orderInfo?.documentData?.documentImages.front !== '' && (
                                        <Grid item xs={12} md={6}>
                                            <CardMedia className={styles.media} image={getBase64Image(orderInfo?.documentData?.documentImages.front || '')} />
                                        </Grid>
                                    )}
                                    {orderInfo?.documentData?.documentImages.back !== '' && (
                                        <Grid item xs={12} md={6}>
                                            <CardMedia className={styles.media} image={getBase64Image(orderInfo?.documentData?.documentImages.back || '')} />
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item className={styles.mainContainer} sm={12} md={6}>
                                        <Card raised>
                                            <CardHeader title={t('orderinfo_clientinfo')} />
                                            <CardContent>
                                                <TableContainer>
                                                    <Table aria-label="simple table">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell align="center">{t('orderinfo_fullname')}</TableCell>
                                                                <TableCell align="center">{`${customerData?.firstName} ${customerData?.lastName}`}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="center">OIB</TableCell>
                                                                <TableCell align="center">{customerData?.personalNumber}</TableCell>
                                                            </TableRow>
                                                            {contact?.email && (
                                                                <TableRow>
                                                                    <TableCell align="center">{t('orderinfo_email')}</TableCell>
                                                                    <TableCell align="center">{contact?.email}</TableCell>
                                                                </TableRow>
                                                            )}
                                                            {contact?.phone?.number && (
                                                                <TableRow>
                                                                    <TableCell align="center">{t('orderinfo_phone')}</TableCell>
                                                                    <TableCell align="center">{' +' + contact.phone.dialCode + contact.phone.number}</TableCell>
                                                                </TableRow>
                                                            )}
                                                            <TableRow>
                                                                <TableCell align="center">{t('orderinfo_city')}</TableCell>
                                                                <TableCell align="center">{customerData?.address.city}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="center">{t('orderinfo_address')}</TableCell>
                                                                <TableCell align="center">{customerData?.address.streetAddress}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="center">{t('orderinfo_idnumber')}</TableCell>
                                                                <TableCell align="center">{orderInfo?.documentData?.documentNumber}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="center">{t('orderinfo_dateofexpiry')}</TableCell>
                                                                <TableCell align="center">{orderInfo?.documentData?.dateOfExpiry}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item className={styles.mainContainer} sm={12} md={6}>
                                        <Card raised>
                                            <CardHeader title={t('orderinfo_requestinfo')} />
                                            <CardContent>
                                                <TableContainer>
                                                    <Table aria-label="simple table">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell align="center">{t('requests_grid_amount')}</TableCell>
                                                                <TableCell align="center">
                                                                    HRK {orderInfo?.loanDetails.amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="center">Status</TableCell>
                                                                <TableCell align="center">{orderInfo?.status}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="center">{t('orderinfo_requestpaymenttype')}</TableCell>
                                                                <TableCell align="center">{orderInfo?.loanDetails.paymentProvider}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="center">{t('requests_grid_merchant')}</TableCell>
                                                                <TableCell align="center">{orderInfo?.merchantData.name}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </>
                )}
            </Box>
        </div>
    );
};

export default OrderInfo;
