import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import type { Action } from '@reduxjs/toolkit';
import type { ThunkAction } from 'redux-thunk';

export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true'
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;