import type { FC } from 'react';
import { useEffect } from 'react';
import logo from '../../assets/img/logo.png';
import { Grid } from '@mui/material';

function generateRandomString(length) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}

async function generateCodeChallenge(codeVerifier) {
    const digest = await crypto.subtle.digest('SHA-256',
        new TextEncoder().encode(codeVerifier));

    return btoa(String.fromCharCode(...new Uint8Array(digest)))
        .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}

export const AuthorizePage: FC = () => {

    useEffect(() => {
        const codeVerifier = generateRandomString(128);
        localStorage.setItem('codeVerifier', codeVerifier);
        generateCodeChallenge(codeVerifier).then((codeChallenge) => {
            localStorage.setItem('codeChallenge', codeChallenge);
            console.log('**** upaloooo ****');
            const authCodeUrl = new URL(process.env.REACT_APP_AUTH_URL ?? '');
            const searchParams = new URLSearchParams({
                client_id: process.env.REACT_APP_CLIENT_ID ?? '',
                redirect_uri: process.env.REACT_APP_REDIRECT_URL + '/login',
                response_type: 'code',
                code_challenge_method: 'S256',
                code_challenge: codeChallenge,
            });
            authCodeUrl.search = searchParams.toString();
            console.log('**** to je link ****', authCodeUrl.toString());

            window.location.href = authCodeUrl.toString();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={ {
                  fontFamily: 'roboto',
                  height: '100vh',
                  paddingBottom: '3rem',
                  width: '100%',
              } }>
            <img style={ {
                bottom: 0,
                height: '60vh',
                left: 0,
                opacity: '20%',
                position: 'fixed',
                right: 0,
                top: '25%',
                width: '100%',
            } }
                 src={ logo }
                 alt="IdentyumLogo" />
        </Grid>
    );
};
