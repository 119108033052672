import { combineReducers } from '@reduxjs/toolkit';
import { reducer as authReducer } from '../slices/auth';
import { reducer as processMonitorReducer } from '../slices/processMonitor';
import { reducer as dataControllerReducer } from '../slices/dataController';
import { reducer as tosControllerReducer } from '../slices/tosController';

const rootReducer = combineReducers({
    auth: authReducer,
    processMonitor: processMonitorReducer,
    dataController: dataControllerReducer,
    tosController: tosControllerReducer,
});

export default rootReducer;
