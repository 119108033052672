import { useEffect } from 'react';
import { getAmlData } from '../../../slices/dataController';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface Props {
    sessionId: string;
    isExpanded: boolean;
}

const AMLQuestionnaire: React.FC<Props> = ({ sessionId, isExpanded }) => {
    const dispatch = useAppDispatch();
    const { amlData } = useAppSelector((state) => state.dataController);

    useEffect(() => {
        if (isExpanded) {
            dispatch(getAmlData(sessionId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div style={{ textTransform: 'uppercase' }}>{amlData?.questionnaireName}</div>
            {amlData?.categories?.map((category) => (
                <div key={category?.caption}>
                    <div>{category?.caption}</div>
                    {category?.questions?.map((question) => (
                        <div key={question?.question}>
                            <div>{question?.question}</div>
                            {question?.answers?.map((answer) => (
                                <div key={answer}>{answer}</div>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
};

export default AMLQuestionnaire;
