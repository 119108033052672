import { AxiosError } from 'axios';
import { ErrorMessageResolver } from './ErrorMessageResolver';

export class ErrorModel {
    message!: string;
    status!: number;
}

export abstract class ErrorModelProvider {
    static provide(err: AxiosError): ErrorModel {
        const errModel = new ErrorModel();
        let status = 500;
        if (err.response) status = err.response.status;

        errModel.message = ErrorMessageResolver.resolve(status);
        errModel.status = status;

        return errModel;
    }
}
