import { useEffect } from 'react';
import { getBlacklistData } from '../../../slices/dataController';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface Props {
    sessionId: string;
    isExpanded: boolean;
}

const BlackListChecker: React.FC<Props> = ({ sessionId, isExpanded }) => {
    const dispatch = useAppDispatch();
    const { blacklistData } = useAppSelector((state) => state.dataController);

    useEffect(() => {
        if (isExpanded) {
            dispatch(getBlacklistData(sessionId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {blacklistData?.blacklists?.map((blacklist) => (
                <div key={blacklist.blacklist}>
                    Passed {blacklist.blacklist}: {blacklist.passed.toString()}
                </div>
            ))}
        </>
    );
};

export default BlackListChecker;
