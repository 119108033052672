interface Props {
    sessionId: string;
    isExpanded: boolean;
}

const LoanConfigurator: React.FC<Props> = ({ sessionId, isExpanded }) => {
    return <>Loan Configurator</>;
};

export default LoanConfigurator;
